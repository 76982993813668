.embeddedServiceHelpButton .helpButton .uiButton {
  background-color: #6E0AD6;
  font-family: "Titillium", sans-serif;
}

.embeddedServiceHelpButton .helpButton .uiButton:focus {
  outline: 1px solid #6E0AD6;
}

.embeddedServiceSidebarHeader {
  background-color: #6E0AD6 !important;
}

.embeddedServiceSidebarExtendedHeader {
  background-color: #6E0AD6 !important
}

.embeddedServiceSidebarButton.uiButton--inverse .label {
  color: #FFF !important;
}

a.embeddedServiceLiveAgentStateChatHeaderOption {
  color: #ffffff !important;
}

.embeddedServiceSidebarButton {
  background-color: #6E0AD6 !important;
}

.embeddedServiceHelpButton .flatButton {
  background-color: #6E0AD6 !important;
}

.embeddedServiceHelpButton .flatButton:hover{
  background-color: #5a08af !important;
}