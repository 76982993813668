@import 'embedded_chat';
@import 'embedded_survey_monkey';

body {
  font-family: 'Nunito Sans', sans-serif;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #4a4a4a;
  * {
    :focus {
      outline: none;
    }
  }
  * {
    span[color=red]{
      color: #ff4444 !important;
      font-size: 14px;
    }
  }
  input, textarea {
    -webkit-appearance: none;
    box-shadow: none !important;
  }


  nav {
    padding: 0 !important;
  }
};
